<template>
  <div class="mt-5 lg:mt-10 px-0 lg:px-20">
    <!---------- Loader ------------------>
    <div v-if="loader" class="h-full w-full">
      <div
        class="
          bg-white bg-opacity-60
          px-8
          py-2
          h-screen
          w-full
          flex
          justify-center
          items-center
        "
      >
        <loader></loader>
      </div>
    </div>
    <!---------- data ------------------>
    <div
      v-if="gdata && !loader"
      class="flex flex-col justify-between w-auto h-full"
    >
      <header class="border-0 border-b border-solid border-gray-300 md:pb-3">
        <div class="flex justify-between items-center">
          <div class="text-center">
            <div class="text-2xl font-semibold">
              {{
                (gdata.doctor.title ? gdata.doctor.title : "DR") +
                "." +
                gdata.doctor.lastName +
                " " +
                gdata.doctor.firstName
              }}
            </div>
            <div class="text-sm text-gray-700">
              <div class="flex flex-wrap w-full text-md">
                {{
                  gdata.doctor.computed.specialities.length > 0
                    ? gdata.doctor.computed.specialities.join(", ")
                    : ""
                }}
              </div>
            </div>
          </div>
          <img
            alt="logo"
            :src="
              'https://api.dok.ma/uploads/' + gdata.doctor.landingPage.logo
                ? gdata.doctor.landingPage.logo
                : gdata.doctor.computed.brandingLogo
            "
            class="h-12 w-12"
          />
          <div class="text-center">
            <div
              class="text-md text-gray-800"
              v-if="gdata.doctor.landingPage.header"
            >
              {{ gdata.doctor.landingPage.header }}
            </div>
            <div
              class="text-md text-gray-800"
              v-else
              v-for="i in gdata.doctor.computed.diplomes"
              :key="i"
            >
              {{ gdata.doctor.computed.diplomes + " ," }}
            </div>
          </div>
        </div>
      </header>
      <div
        class="flex-grow flex-1 flex flex-col mt-5"
        style="min-height: 200px"
      >
        <div class="flex flex-row justify-between mb-10 md:mb-20">
          <div class="text-md">
            Bénéficiaire:<span class="font-bold"
              >{{ gdata.patient.firstName
              }}<span class="uppercase">{{
                " " + gdata.patient.lastName
              }}</span></span
            >
          </div>
          <div class="text-md">
            Fait le:<span class="font-bold">
              {{ gdata.prescription.date }}
            </span>
          </div>
        </div>
        <div>
          <div
            class="
              hidden
              xl:grid
              gap-x-5
              grid-cols-4
              mb-3
              md:mb-10
              border-0 border-b border-solid border-gray-300
            "
          >
            <div class="font-extrabold text-lg whitespace-no-wrap">
              NOM DU MÉDICAMENT
            </div>
            <div class="font-extrabold text-lg whitespace-no-wrap">
              DURÉE DU TRAITEMENT
            </div>
            <div class="font-extrabold text-lg whitespace-no-wrap">PÉRIODE</div>
            <div class="font-extrabold text-lg whitespace-no-wrap"></div>
          </div>
          <div
            style="min-height: 100px"
            v-for="(presc_medic, index) in gdata.listMed"
            :key="index"
          >
            <div class="grid gap-x-5 grid-cols-4 pb-3">
              <div
                class="
                  flex flex-col
                  text-gray-900 text-normal
                  md:text-lg
                  capitalize
                  font-bold
                  whitespace-pre-line
                "
              >
                {{ presc_medic.name.toLowerCase() }}
              </div>
              <div class="flex flex-col md:flex-row lg-flex-row">
                <span
                  class="
                    text-gray-900 text-normal
                    md:text-lg
                    capitalize
                    whitespace-pre-line
                  "
                  >{{ presc_medic.treatmentDuration + " jours" }}</span
                >
                <span
                  class="
                    text-gray-900 text-normal
                    md:text-lg
                    capitalize
                    whitespace-pre-line
                  "
                  >, {{ presc_medic.dailyDosage + "x/jour" }}</span
                >
              </div>
              <div
                class="text-gray-800 text-normal md:text-lg whitespace-pre-line"
              >
                <span v-for="(item, j) in presc_medic.dayPeriod" :key="j">{{
                  gdata.timesOfTheDay[item.toUpperCase()]["fr"] + ", "
                }}</span>
                {{
                  (presc_medic.timePeriod ? presc_medic.timePeriod : "") +
                  (presc_medic.mealPeriod === "AFTER"
                    ? "/Après repas"
                    : "/Avant repas")
                }}
              </div>
            </div>
            <div
              class="text-right aline"
              v-for="(sgrd, index) in gdata.groupedServedDgrus[presc_medic._id]"
              :key="index"
            >
              {{ sgrd.quantity }} Unités vendu le
              <b>{{ moment()(sgrd.created_at).format("DD/MM") }}</b> par
              <b
                >{{
                  sgrd.pharmacy.localCommercialName
                    ? sgrd.pharmacy.localCommercialName
                    : sgrd.pharmacy.lastName + " " + sgrd.pharmacy.lastName
                }},</b
              >
            </div>
            <hr class="border-gray-400" />
          </div>
        </div>
      </div>
      <footer class="border-0 border-t border-solid border-gray-300 mt-5">
        <div class="flex flex-col">
          <div
            v-if="gdata.doctor.landingPage.footer"
            class="text-base text-gray-800 uppercase pb-1"
          >
            {{ gdata.doctor.landingPage.footer }}
          </div>
          <div v-else class="text-base text-gray-900 uppercase pb-1">
            {{ gdata.doctor.computed.fullAddress }}
          </div>
          <div v-if="gdata.doctor.fix" class="font-bold text-gray-900">
            FIX:<span class="text-base text-gray-800">
              {{ gdata.doctor.fix }}</span
            >
          </div>
        </div>
      </footer>
    </div>
    <!-- Null Data patients -->
    <div
      v-if="!gdata && !loader"
      class="bg-white 0 px-8 py- w-full flex justify-center items-center"
    >
      <img alt="vide" src="/svg/null_data.svg" class="max-h-300 xl:max-h-500" />
    </div>
  </div>
</template>
<script>
const loader = () => import("@/views/global-components/loader");
import moment from "moment";
export default {
  data() {
    return {
      gdata: null,
      loader: true,
    };
  },
  components: { loader },
  async mounted() {
    try {
      await this.$store.dispatch("pharmacy/GET_PRESCRIPTION", {
        id: this.$route.params.id,
        category: "DRUG",
        onData: this.onPrescriptionData,
      });
    } finally {
      this.loader = false;
    }
  },
  methods: {
    moment() {
      return moment;
    },
    onPrescriptionData: function (data) {
      if (data.prescription) this.gdata = data;
    },
  },
};
</script>
